import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { animated, useSpring, config } from 'react-spring';

export default function Sheets({isTabletOrMobileDevice}) {

const [postData, setPostData] = useState(null);
const {slug} = useParams();

useEffect(() => {
    sanityClient
    .fetch(
        `*[_type == "sheetsSection"]{
          "singleSheets": sheetsList[]{"pdfFileImage": pdfFileImage.asset->url, thumbnailLink, sheetEntryInfo}
        }    
        `
    )
    .then((data) => setPostData(data[0]))
    .catch(console.error);
},[slug]);

const fadeIn = useSpring({
  opacity: postData ? 1 : 0,
  filter: postData ? 'blur(0px)' : 'blur(5px)'
})

const link = (props) => {
  return <a href={props.mark.href} target="_blank">{props.children}</a>
}

if (!postData) return <div></div>;
  return (
    <div>
      <animated.div style={fadeIn} id="sheetsSectionContent">
        {console.log(postData.singleSheets)}
        {postData.singleSheets.map((post, index) => { return(
          <div className="singleSheet" key={index}>
            <a href={post.thumbnailLink} target="_blank">
              <img src={`${post.pdfFileImage}?h=50`}/>
            </a>
            <div className="singleSheetInfo">
              <BlockContent
                    blocks={post.sheetEntryInfo}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                    serializers={{marks: {link}}}
              />
            </div>
          </div>
        )})}
              {isTabletOrMobileDevice ?
        <div className='padding'></div>
        : 
        ''
        }
      </animated.div>
    </div>
  );
}