import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { animated, useSpring, config } from 'react-spring';

export default function Releases() {

const [postData, setPostData] = useState(null);
const {slug} = useParams();
useEffect(() => {
    sanityClient
    .fetch(
        `*[_type == "releasesSection"]{
          "singleReleases": releasesList[]{"image": releaseEntryImage.asset->url,releaseEntryTitle,releaseUrlSpotify,releaseUrlAppleMusic,thumbnailLink}
          }
        `
    )
    .then((data) => setPostData(data[0]))
    .catch(console.error);
},[slug]);

const fadeIn = useSpring({
  opacity: postData ? 1 : 0,
  filter: postData ? 'blur(0px)' : 'blur(5px)'
})

const link = (props) => {
  return <a href={props.mark.href} target="_blank">{props.children}</a>
}

if (!postData) return <div></div>;
  return (
    <div>
      <animated.div style={fadeIn} id="releasesSectionContent">
        {console.log(postData)}
        {postData.singleReleases.map((post, index) => { return(
          <div className="singleRelease" key={index}>
            <a href={post.thumbnailLink} target="_blank">
              <img className="singleReleaseImage" src={post.image} />
            </a>
            <div className="singleReleaseText">
              <BlockContent
                    blocks={post.releaseEntryTitle}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                    serializers={{marks: {link}}}
              />
            </div>
            <div className="singleReleaseUrls">
              {post.releaseUrlSpotify ? <a href={post.releaseUrlSpotify} target="_blank">
                <img className={'spotifyLink'} src={process.env.PUBLIC_URL + '/spotify.png'} />
              </a> : ''}
              {post.releaseUrlAppleMusic ? <a href={post.releaseUrlAppleMusic} target="_blank">
                <img className={'appleMusicLink'} src={process.env.PUBLIC_URL + '/applemusic.png'}/>
              </a> : ''}
            </div>
          </div>
        )})}
        <div class='padding'></div>
      </animated.div>
    </div>
  );
}