import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animated, useSpring, config } from 'react-spring';
import './Menu.style.css';
import { useParams } from "react-router-dom";

const Menu = ({isTabletOrMobileDevice,displayMenu,toggleDisplayMenu}) =>{

const {slug} = useParams();
console.log(slug);

const menuToggle = useSpring({
    opacity: displayMenu ? 1 : 0,
    display: displayMenu ? 'block' : 'none'
})

const rotateBtn = useSpring({
    transform: displayMenu ? 'rotate(0deg)' : 'rotate(45deg)'
})

const toggleMenu = () =>{
    toggleDisplayMenu(!displayMenu);
}

const closeMenu = () =>{
    toggleDisplayMenu(false);
}

const displayTitleFromSlug = (slug) => {
    switch(slug){
        case 'Releases':
            return 'Releases'
        break;
        case 'About':
            return 'Matteo Myderwyk'
        break;
        case 'Videos':
            return 'Videos'
        break;
        case 'Sheets':
            return 'Sheets'
        break;
        case 'Contact':
            return 'Contact'
        break;
        case 'Live':
            return 'Live'
        break;
        default:
            return 'Matteo Myderwyk'

    }
}
console.log(slug);
return(
    <div>
        {isTabletOrMobileDevice ?
        <div className='menuOuterWrapper'>

            <div className="menuBtnWrapper">
                <animated.div onClick={() => toggleMenu()} style={rotateBtn} className="toggleMenuBtn"><img src={process.env.PUBLIC_URL + '/cross.png'} /></animated.div>
            </div>

            <div className='mobileMenuSectionTitle'>
                {
                    
                    (slug == undefined) ? 
                        <Link onClick={() => closeMenu()} to={"/About"}>Matteo Myderwyk</Link>
                        :
                        <Link onClick={() => closeMenu()} to={"/"}>{displayTitleFromSlug(slug)}</Link>
                }
                    
            </div>
            <animated.div style={menuToggle}>
                {
                    (slug == 'About' || slug == undefined) ? 
                    ''
                    :
                    <div className='mobileMenuSection'>
                        <Link onClick={() => toggleMenu()} to={"/About"}>Matteo Myderwyk</Link>
                    </div>
                }
               {
                    (slug == 'Releases') ? 
                    ''
                    :
                    <div className='mobileMenuSection'>
                        <Link onClick={() => toggleMenu()} to={"/Releases"}>Releases</Link>
                    </div>
                }
                {
                    (slug == 'Live') ? 
                    ''
                    :
                    <div className='mobileMenuSection'>
                        <Link onClick={() => toggleMenu()} to={"/Live"}>Live</Link>
                    </div>
                }
               {
                    (slug == 'Videos') ? 
                    ''
                    :
                    <div className='mobileMenuSection'>
                        <Link onClick={() => toggleMenu()} to={"/Videos"}>Videos</Link>
                    </div>
                }
                {
                    (slug == 'Sheets') ? 
                    ''
                    :
                    <div className='mobileMenuSection'>
                        <Link onClick={() => toggleMenu()} to={"/Sheets"}>Sheets</Link>
                    </div>
                }
                {
                    (slug == 'Contact') ? 
                    ''
                    :
                    <div className='mobileMenuSection'>
                        <Link onClick={() => toggleMenu()} to={"/Contact"}>Contact</Link>
                    </div>
                }
            </animated.div>
        </div>
        :
        <div className='menuWrapper'>
            <div className='menuTop'>
                <div className='menuSection'>
                    <Link className={(slug == 'About') ? 'menuBtnExpanded left' : 'menuBtnRegular left'} to={(slug == 'About') ? '/' : '/About'}>Matteo Myderwyk</Link>
                </div>
                <div className='menuSection'>
                    <Link className={(slug == 'Releases') ? 'menuBtnExpanded center' : 'menuBtnRegular center'} to={(slug == 'Releases') ? '/' : '/Releases'}>Releases</Link>
                </div>
                <div style={{textAlign:'right'}} className='menuSection'>
                    <Link className={(slug == 'Live') ? 'menuBtnExpanded right' : 'menuBtnRegular right'} to={(slug == 'Live') ? '/' : '/Live'}>Live</Link>
                </div>
            </div>
            <div className='menuBottom'>
                <div className='menuSection'>
                    <Link className={(slug == 'Videos') ? 'menuBtnExpanded left' : 'menuBtnRegular left'} to={(slug == 'Videos') ? '/' : '/Videos'}>Videos</Link>
                </div>
                <div className='menuSection'>
                    <Link className={(slug == 'Sheets') ? 'menuBtnExpanded center' : 'menuBtnRegular center'} to={(slug == 'Sheets') ? '/' : '/Sheets'}>Sheets</Link>
                </div>
                <div style={{textAlign:'right'}} className='menuSection'>
                    <Link className={(slug == 'Contact') ? 'menuBtnExpanded right' : 'menuBtnRegular right'} to={(slug == 'Contact') ? '/' : '/Contact'}>Contact</Link>
                </div>
            </div>
        </div>
        }
    </div>
)}

export default Menu;
