import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { animated, useSpring, config } from 'react-spring';

export default function Contact() {

const [postData, setPostData] = useState(null);
const {slug} = useParams();

useEffect(() => {
    sanityClient
    .fetch(
        `*[_type == "contactSection"]{contact,facebookLink,
          twitterLink,
          instagramLink,
          soundcloudLink}`
    )
    .then((data) => setPostData(data[0]))
    .catch(console.error);
},[slug]);

const fadeIn = useSpring({
    opacity: postData ? 1 : 0,
    filter: postData ? 'blur(0px)' : 'blur(5px)'
  })

if (!postData) return <div></div>;
  return (
        <animated.div style={fadeIn} id="contactSectionContent">
          <div>
          <BlockContent
              blocks={postData.contact}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
          />
          </div>
          <div className='contactSocialIcons'>
            <div className='contactSocialIcon facebook'>
              <a href={postData.facebookLink} target="_blank">
                <img src={process.env.PUBLIC_URL + '/facebook.png'} />
              </a>
            </div>
            <div className='contactSocialIcon twitter'>
              <a href={postData.twitterLink} target="_blank">
                <img src={process.env.PUBLIC_URL + '/twitter.png'} />
              </a>
            </div>
            <div className='contactSocialIcon instagram'>
              <a href={postData.instagramLink} target="_blank">
                <img src={process.env.PUBLIC_URL + '/instagram.png'} />
              </a>
            </div>
          </div>
      </animated.div>
  );
}