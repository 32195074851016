import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { animated, useSpring, config } from 'react-spring';

import sanityClient from "../client.js";
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import BlockContent from "@sanity/block-content-to-react";

export default function Videos({isTabletOrMobileDevice}) {

const [postData, setPostData] = useState(null);
const [expandedVideo, setExpandedVideo] = useState(null); 
const {slug} = useParams();

const windowFullScale = (window.innerWidth - 40); //width of thumbnail
const windowInnerHeight = window.innerHeight - 100;

const springFadeIn = useSpring({
  opacity: postData && expandedVideo === null ? 1 : postData && expandedVideo >= 0 ? 0.5 : 0,
  filter: postData && expandedVideo === null ? 'blur(0px)' : postData && expandedVideo >= 0 ? 'blur(2px)' : 'blur(5px)'
})

const expandedVideoStyle = useSpring({
  opacity: postData ? 1 : 0
})

// const springVideoExpand = useSpring({
//   height: expandedVideo ? '100%' : '200px'
// })

const opts = {
  playerVars: {
    controls: 0,
    modestbranding:1,
    fs:0,
  },
};

useEffect(() => {
    sanityClient
    .fetch(
        `*[_type == "videosSection"]{videoList}`
    )
    .then((data) => setPostData(data[0]))
    .catch(console.error);
},[slug]);

const toggleVideoExpand = (index) =>{
  console.log(index);
  // if (index == expandedVideo) {
  //   console.log('expanding' + index);
  //   setExpandedVideo(null)
  // } else{
    setExpandedVideo(index)
  //}
}

const ExpandedVideoDisplay = () =>{
  const videoId = `https://www.youtube.com/embed/${getYouTubeId(postData.videoList[expandedVideo].videoEntryUrl.url)}?controls=0?modestbranding=1`;
  return(
    <div className='expandedVideoDisplayInnerWrapper'>
      <div className='videoEntryYoutubeEmbed'>
        <iframe 
          src={videoId}
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>
        {/* <YouTube className='videoEntryYoutubeEmbed' opts={opts} videoId={videoId} /> */}
        <div className="expandedVideoDisplayInnerWrapperInfo">
          <BlockContent
              blocks={postData.videoList[expandedVideo].videoEntryInfo}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
          />
        </div>
        <div className='expandedVideoCloseBtn' onClick={() => setExpandedVideo(null)}>
          <img className={'cross'} src={process.env.PUBLIC_URL + '/cross.png'} />
        </div>
      </div>
  )
}

const VideoGridDisplay = ({post}) =>{
  const videoId = getYouTubeId(post.videoEntryUrl.url);
  return (
    <div>
      <div className="videoEntryWrapper">
        <div className="videoEntryThumbnail" style={{backgroundImage: `url(https://img.youtube.com/vi/${videoId}/0.jpg)`}}>
        </div>
      </div>

      <div className="videoEntryInfo">
        <BlockContent
            blocks={post.videoEntryInfo}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
        />
      </div>
    </div>
  )
}

if (!postData) return <div></div>;

  return (
    <div>
        {expandedVideo !== null ? 
          <animated.div className='expandedVideoDisplayWrapper' style={expandedVideoStyle} >
            <ExpandedVideoDisplay />
          </animated.div>
        : ''}
      <animated.div style={springFadeIn} id="videosSectionContent">
        {console.log(postData.videoList)}
        {postData.videoList.map((post, index) => (
          <div className="videosGridElement" key={index} onClick={() => toggleVideoExpand(index)}>
            <VideoGridDisplay post={post} index={index} />
          </div>
        ))}
              {isTabletOrMobileDevice ?
        <div className='padding'></div>
        : 
        ''
        }
      </animated.div>
    </div>
  );
}