import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Background = () =>{

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'src/Components/Background/helloworld.js';
    //     script.async = true;
    //     document.body.appendChild(script);
    //     // return () => {
    //     //   document.body.removeChild(script);
    //     // }
    // }); 

    return(
    <div id='background'>
        <canvas id="boids"></canvas>
    </div>
)}

export default Background;