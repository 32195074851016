import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { animated, useSpring, config } from 'react-spring';

import moment from 'moment';

export default function Live({isTabletOrMobileDevice}) {

const [postData, setPostData] = useState(null);
const {slug} = useParams();

useEffect(() => {
    sanityClient
    .fetch(
        `*[_type == "liveEntriesSection"]{
          "liveEntries": liveEntriesList[]{
            liveEntryDate,
            liveEntryVenue,
            liveEntryCity,
            liveEntryTicketUrl
          }
        }`
    )
    .then((data) => setPostData(data[0]))
    .catch(console.error);
},[slug]);

const fadeIn = useSpring({
  opacity: postData ? 1 : 0,
  filter: postData ? 'blur(0px)' : 'blur(5px)'
})

if (!postData) return <div></div>;

  return (
    <div>
      
        <animated.div style={fadeIn} id="liveSectionContent">
        {postData.liveEntries.map((post, index) => {
        console.log(post)
        var date = moment(post.liveEntryDate, "YYYY-MM-DD");
         const year = date.year();
         const month = date.month() + 1;
         const day = date.date(); 
      
        return(
          <div className="singleLiveSectionEntry" key={index}>
            <div className="singleLiveSectionDate">
              <div className="dateItemDay">{day}.</div>
              <div className="dateItemMonth">{month}.</div>
              <div className="dateItemYear">{year}</div>
            </div>
            <div className="singleLiveSectionVenue">{post.liveEntryVenue}</div>
            <div className="singleLiveSectionMusician">{post.liveEntryCity}</div>
            <div className="singleLiveSectionTicketUrl"><a className={'underline'} target="_blank" href={post.liveEntryTicketUrl}>Tickets</a></div>
          </div>
        )})}
              {isTabletOrMobileDevice ?
              <div className='padding'></div>
              : 
              ''
              }
      </animated.div>
    </div>
  );
}