import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, useParams } from "react-router-dom";
import sanityClient from "./client.js";
import BlockContent from "@sanity/block-content-to-react";
import { animated, useSpring, config } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import {GrClose} from 'react-icons/gr'

import './App.css';

import About from "./Pages/about.page.jsx";
import Contact from "./Pages/contact.page.jsx";
import Live from "./Pages/live.page.jsx";
import Sheets from "./Pages/sheets.page.jsx";
import Releases from "./Pages/releases.page.jsx";
import Videos from "./Pages/videos.page.jsx";

import Menu from './Components/Menu/Menu.component';
import Background from './Components/Background/Background.component';

function App() {

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 1000px)'
  })

  const [displayMenu, toggleDisplayMenu] = useState(false)
  const [frontpageImage,setFrontpageImage] = useState(null)

  const hideContentFromMenu = useSpring({
    opacity: !displayMenu ? 1 : 0
  })

useEffect(() => {
  sanityClient
  .fetch(
      `*[_id == "frontPage"]{'imageUrl': image.asset->url, ...}`
  )
  .then((data) => {setFrontpageImage(data[0])})
  .catch(console.error);
},[]);

const FrontPageImage = ()=>{
  // console.log(frontpageImage)
  if (frontpageImage.imageUrl){
    return (
      <div className="frontpage-image-wrapper">
        
          <div className="frontpage-image">
            <div className="close-wrapper" onClick={()=>setFrontpageImage(null)}>
              <GrClose/>
            </div>
            {'imageUrl' in frontpageImage ?
            <a href={frontpageImage.image.url}>
              <img src={frontpageImage.imageUrl}/>
            </a>
            :''}
            <div className="frontpage-caption">
              <a href={frontpageImage.image.url}>
                {frontpageImage.image.caption}
              </a>
            </div>
          </div>
      </div>
    )
  }else{
    return <></>
  }
}

//render={(props) => <PropsPage {...props} title={`Props through render`} />} 
// make menu a 'route' component so it can access params
//https://stackoverflow.com/questions/63446148/react-router-dom-nested-routing-or-how-to-pass-a-slug
  return (
    <div className="page-wrapper">
      <BrowserRouter>
        <Route
          path={["/:slug", "*"]}
          render={(props) => (<Menu {...props} 
              isTabletOrMobileDevice={isTabletOrMobileDevice} 
              displayMenu={displayMenu} 
              toggleDisplayMenu={toggleDisplayMenu}  />)
          } 
        />
        {frontpageImage
        ?
          <Route exact path="/" render={(props) => (<FrontPageImage />)}/>
        :''}

            <animated.div className="animate-div" style={hideContentFromMenu}>
              <Route path="/About" render={displayMenu ? '' : (props) => (<About {...props} isTabletOrMobileDevice={isTabletOrMobileDevice} />)}/>
              <Route path="/Sheets" render={displayMenu ? '' : (props) => (<Sheets {...props} isTabletOrMobileDevice={isTabletOrMobileDevice} />)}/>
              <Route path="/Releases" render={displayMenu ? '' : (props) => (<Releases {...props} isTabletOrMobileDevice={isTabletOrMobileDevice} />)}/>
              <Route path="/Live" render={displayMenu ? '' : (props) => (<Live {...props} isTabletOrMobileDevice={isTabletOrMobileDevice} />)}/>
              <Route path="/Videos" render={displayMenu ? '' : (props) => (<Videos {...props} isTabletOrMobileDevice={isTabletOrMobileDevice} />)}/>
              <Route path="/Contact" render={displayMenu ? '' : (props) => (<Contact {...props} isTabletOrMobileDevice={isTabletOrMobileDevice} />)}/>
            </animated.div>
      </BrowserRouter>
      <Background />
    </div>
  );
}
export default App;
