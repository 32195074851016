import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { animated, useSpring, config } from 'react-spring';

export default function About({isTabletOrMobileDevice}) {

const [postData, setPostData] = useState(null);
const {slug} = useParams();

useEffect(() => {
    sanityClient
    .fetch(
        `*[_type == "aboutSection"]{about}`
    )
    .then((data) => setPostData(data[0]))
    .catch(console.error);
},[slug]);

const fadeIn = useSpring({
  opacity: postData ? 1 : 0,
  filter: postData ? 'blur(0px)' : 'blur(5px)'
})

if (!postData) return <div></div>;
  return (
    <animated.div style={fadeIn} id="aboutSectionContent">
        <BlockContent
            blocks={postData.about}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
        />
        {isTabletOrMobileDevice ?
        <div className='padding'></div>
        : 
        ''
        }
    </animated.div>
  );
}